<template>
  <base-section id="pro-features">
    <v-img
      :src="require('@/assets/logo.svg')"
      class="mx-auto mb-8"
      max-width="128"
    />

    <base-section-heading :title="$ml.get('function_compare')">
      {{$ml.get('become_owner')}}
    </base-section-heading>

    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" md="7">
          <v-simple-table>
            <thead>
              <tr>
                <th />
                <th class="body-1 font-weight-bold text-center">
                 {{$ml.get('bank_depo')}}
                </th>
                <th class="body-1 font-weight-bold text-center">SYRF</th>
              </tr>
            </thead>

            <tbody class="body-1">
              <tr>
                <td>{{$ml.get('profit')}}</td>
                <td class="text-center font-weight-bold">
                  <v-icon color="error"> 7% </v-icon>
                </td>
                <td class="text-center font-weight-bold">
                  <template>
                    <v-icon color="success">
                      40+
                    </v-icon>
                  </template>
                </td>
              </tr>
              <tr>
                <td>{{$ml.get('removal_possibility')}}</td>
                <td class="text-center font-weight-bold">
                  <v-icon color="error"> mdi-close </v-icon>
                </td>
                <td class="text-center font-weight-bold">
                  <template>
                    <v-icon color="success">
                      mdi-check
                    </v-icon>
                  </template>
                </td>
              </tr>
              <tr>
                <td>{{$ml.get('support_all_stages')}}</td>
                <td class="text-center font-weight-bold">
                  <v-icon color="success"> mdi-check </v-icon>
                </td>
                <td class="text-center font-weight-bold">
                  <template>
                    <v-icon color="success">
                      mdi-check
                    </v-icon>
                  </template>
                </td>
              </tr>
              <tr>
                <td>{{$ml.get('compilance')}}</td>
                <td class="text-center font-weight-bold">
                  <v-icon color="success"> mdi-check </v-icon>
                </td>
                <td class="text-center font-weight-bold">
                  <template>
                    <v-icon color="success">
                      mdi-check
                    </v-icon>
                  </template>
                </td>
              </tr>
              <tr>
                <td>{{$ml.get('anonimity')}}</td>
                <td class="text-center font-weight-bold">
                  <v-icon color="error"> mdi-close </v-icon>
                </td>
                <td class="text-center font-weight-bold">
                  <template>
                    <v-icon color="success">
                      mdi-check
                    </v-icon>
                  </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>

        <v-col cols="12" md="5">
          <v-card
            elevation="0"
            class="mb-12"
            align="center"
            style="display: flex; justify-content: center; overflow: hidden"
          >
            <img src="../home/gif.gif" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionProChart",

  data: () => ({

  }),
};
</script>
